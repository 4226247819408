import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonUnderline,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectTextAlign,
  RichTextEditor,
  type RichTextEditorRef,
} from "mui-tiptap";
import { useRef } from "react";

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
  defaultValue: string;
};

export default function DebounceRichTextEditor(props: DebounceProps) {
  const { handleDebounce, debounceTimeout, defaultValue } = props;
  const rteRef = useRef<RichTextEditorRef>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleContentChange = () => {
    const newContent = rteRef.current?.editor?.getHTML() || "";
    // setContent(newContent);

    // Debounce logic
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      handleDebounce(newContent);
    }, debounceTimeout);
  };

  return (
    <div
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      <RichTextEditor
        ref={rteRef}
        extensions={[
          StarterKit,
          Underline,
          TextAlign.configure({ types: ["heading", "paragraph"] }),
        ]}
        content={defaultValue}
        onUpdate={handleContentChange}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuSelectHeading />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuButtonUnderline />
            <MenuDivider />
            <MenuSelectTextAlign />
          </MenuControlsContainer>
        )}
      />
    </div>
  );
}
