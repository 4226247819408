import {
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState, useMemo, useEffect } from "react";

export default function CruCuit() {
  const [poids, setPoids] = useState<number>(100);
  const [isCru, setIsCru] = useState<string>("cru");
  const [element1, setElement1] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);

  const carbsElements = useMemo(
    () => [
      { name: "Riz", multiplicator: 3, id: 0},
      { name: "Pâtes", multiplicator: 2.5, id: 1 },
      { name: "Semoule", multiplicator: 3.5, id: 2 },
      { name: "Pommes de terre", multiplicator: 1, id: 3 },
      { name: "Lentilles", multiplicator: 3, id: 4 },
    ],
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCru((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    const compute = () => {
      const multiplicator1 = carbsElements[element1]?.multiplicator || 0;
      if (isCru === "cru") setTotal(poids * multiplicator1);
      else setTotal(poids / multiplicator1);
    };

    compute();
  }, [poids, element1, carbsElements, isCru]);
  return (
    <>
      <Typography>Convertisseur cru / cuit</Typography>
      <Grid container spacing={2} p={1}>
        <Grid item xs={12} sm={3}>
          <TextField
            type="number"
            value={poids}
            onChange={(e) => {
              setPoids(Number(e.target.value) || 0);
            }}
            label="Grammes"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            value={element1}
            onChange={(e) => {
              setElement1(Number(e.target.value));
            }}
          >
            {carbsElements.map((carb) => (
              <MenuItem value={carb.id} key={carb.id}>
                {carb.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={3} alignSelf="center">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={isCru}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="cru" control={<Radio />} label="Cru" />
              <FormControlLabel value="cuit" control={<Radio />} label="Cuit" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} alignSelf="center">
          {element1 !== 1 && (
            <Typography>
              Total: {Math.round(total)} g {isCru === "cru" ? "Cuit" : "Cru"}
            </Typography>
          )}
          {element1 === 1 && (
            <Typography>
              Total: {Math.round(total)} g {isCru === "cru" ? "Cuit" : "Cru"}
              {isCru === "cru" &&
                ` (ou ${Math.round(
                  total * 0.7
                )}g si cuit au four; ${Math.round(
                  total * 0.5
                )}g si cuit au Air Fryer)`}
              {isCru === "cuit" &&
                ` (ou ${Math.round(
                  total / 0.7
                )}g si cuit au four; ${Math.round(
                  total / 0.5
                )}g si cuit au Air Fryer)`}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}
